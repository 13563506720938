import { configureStore } from '@reduxjs/toolkit'
import Globals from './reducers/globals'
import Player from './reducers/player'
import Auth from './reducers/auth'
import User from './reducers/user'

export const store = configureStore({
    reducer: {
        Auth,
        Globals,
        Player,
        User,
    },
})