// https://mui.com/customization/theme-components/
// https://mui.com/material-ui/customization/typography/
import FontRegular from '../assets/fonts/FantaPlayful-Regular.woff2'
import palette from './palette'
import spacing from './spacing'

const components = {
    MuiCssBaseline: {
        styleOverrides: `
            @font-face {
                font-family: 'CustomFont';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: url(${FontRegular}) format('woff2');
            }
        `
    },

    MuiLink: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                color: theme.palette.colors.custom,
                textDecoration: `underline ${theme.palette.colors.white}`,
                ':hover': {
                    textDecoration: 'none',
                },
            }),
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ':hover': {
                    backgroundColor: theme.palette.colors.lightGray
                },
            }),
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                color: theme.palette.colors.white,
                border: `1px solid ${theme.palette.colors.white}`,
                height: '50px',
                padding: theme.spacing(3),
                width: '100%',
                fontSize: 18,
                fontWeight: 800,
                ':hover': {
                    backgroundColor: theme.palette.colors.lightGray
                },
            }),
        },
        variants: [
            {
                props: { variant: 'quiz' },
                style: {
                    color: palette.quiz.purple,
                    backgroundColor: palette.colors.white,
                    borderRadius: 0,
                    height: '12vmin',
                    padding: spacing*3,
                    width: '100%',
                    fontSize: '7vmin',
                    fontWeight: 400,
                    // transform: 'skewY(-2deg)',
                    ':hover': {
                        color: palette.colors.blue,
                        backgroundColor: palette.colors.white
                    },
                },
            }
        ]
    },
}

export default components
