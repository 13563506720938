import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const globalsSlice = createSlice({
    name: 'globals',
    initialState,
    reducers: {
        globalsState: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    globalsState,
} = globalsSlice.actions

export default globalsSlice.reducer