// https://mui.com/customization/palette/

const palette = {
    background: {
        default: '#dfb7d4',
    },
    status: {
        danger: '#c46868',
    },
    text: {
        primary: '#ffffff',
    },
    primary: {
        main:  '#ffffff',
    },
    quiz: {
        purple: '#75559d',
        salmon: '#f1847a',
        orange: '#e9593c',
        red: '#e30332',
        green: '#238012',
        yellow: '#f8af01'
    },
    colors: {
        blue: '#3863cc',
        black: '#000',
        white: '#FFF',
        gray: '#4F4F4F',
        lightGray: '#a8a8a8',
        main: '#FFF',
        red: '#c46868',
        custom: '#DBB279',
    }
}

export default palette